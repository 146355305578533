<template>
  <div>

    <b-card
      v-if="isAdmin"
      no-body
    >
      <b-card-header class="pb-50">
        <h5>Filters</h5>
      </b-card-header>
      <b-card-body>
        <b-row class="align-items-center">
          <b-col
            cols="12"
            lg="4"
          >
            <validation-provider
              #default="validationContext"
              name="date range"
            >
              <b-form-group label="Date Range">
                <date-range-picker
                  v-model="dateRange"
                  auto-apply
                  show-dropdowns
                  :control-container-class="`form-control ${getValidationStateNonBootstrap(validationContext)}`"
                  class="w-100"
                  @update="fetchWithDates"
                >
                  <template
                    v-slot:footer
                  >
                    <!-- Empty footer -->
                  </template>
                </date-range-picker>

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            lg="4"
          >
            <b-form-group label="Customer">
              <user-picker
                v-model="userId"
                clearable
                @input="$nextTick(() => fetchWithDates())"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            lg="2"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            offset-lg="7"
          >
            <spinner-button
              ref="newOrder"
              block
              variant="primary"
              @click="setupOrder()"
            >
              New Order
            </spinner-button>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refOrdersTable"
        class="position-relative"
        :items="orders"
        responsive
        :fields="tableColumns"
        primary-key="order_id"
        show-empty
        empty-text="No matching records found"
        :busy="loading"
      >
        <template v-slot:table-busy>
          <div class="d-flex justify-content-center my-4">
            <b-spinner
              type="grow"
              style="width: 3rem; height: 3rem;"
              variant="primary"
            />
          </div>
        </template>

        <template v-slot:cell(customer)="data">
          {{ data.item.user.user_first_name }} {{ data.item.user.user_last_name }}
        </template>

        <template v-slot:cell(status)="data">
          <b-badge
            pill
            :variant="variantForStatus(data.item.order_status)"
            class="text-capitalize"
          >
            {{ statusForOrder(data.item) }}
          </b-badge>
        </template>

        <template v-slot:cell(order_date)="data">
          {{ data.item.order_datetime | dateFormat }}
        </template>

        <template v-slot:cell(actions)="data">
          <template v-if="data.item.order_status === OrderStatuses.ORDER_STATUS_OPEN || data.item.order_status === OrderStatuses.ORDER_STATUS_HELD">
            <b-button
              variant="primary"
              size="sm"
              @click="$router.push({ name: 'shop', params: { order_id: data.item.order_id } })"
            >
              <span class="d-none d-lg-block">
                Continue
              </span>
            </b-button>
          </template>
          <template v-else-if="data.item.order_status === OrderStatuses.ORDER_STATUS_SCHEDULED">
            <b-button
              variant="danger"
              size="sm"
              @click="toggleConfirmDelete(data.item)"
            >
              <span class="d-none d-lg-block">
                Cancel
              </span>
            </b-button>
          </template>
          <template v-else-if="data.item.order_status === OrderStatuses.ORDER_STATUS_READY && isAdmin">
            <spinner-button
              ref="buttons"
              variant="primary"
              size="sm"
              small
              @click="completeOrder(data)"
            >
              Complete
            </spinner-button>
            <spinner-button
              variant="outline-primary"
              size="sm"
              small
              class="ml-1"
              :loading="printingOrderIds.indexOf(+data.item.order_id) > -1"
              @click="printOrderLabel(data.item)"
            >
              Print Label
            </spinner-button>
          </template>
          <template
            v-else
          >
            <b-button
              variant="primary"
              size="sm"
              @click="showSignatureModal(data.item)"
            >View Details</b-button>
          </template>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <icon-modal
      ref="confirm"
      icon="XOctagonIcon"
      icon-classes="text-danger"
      @close="orderId = null"
    >
      <p class="text-center">
        Are you sure you would like to cancel this order?
      </p>
      <template v-slot:footer>
        <b-button
          variant="default"
          @click="$refs.confirm.close()"
        >
          Go Back
        </b-button>
        <spinner-button
          variant="danger"
          :loading="deleting"
          @click="deleteOrder"
        >
          Cancel
        </spinner-button>
      </template>
    </icon-modal>
    <b-modal
      v-model="showNewModalOrder"
      hide-header
      @hidden="resetSetupOrderDetails"
    >
      <validation-observer
        ref="refFormObserver"
        #default="{ handleSubmit }"
      >

        <b-form
          id="createOrderForm"
          @submit.prevent="handleSubmit(createOrder)"
        >

          <validation-provider
            #default="validationContext"
            name="location"
            rules="required"
          >
            <b-form-group label="Location">
              <location-picker
                v-model="newOrderSetupDetails.locationId"
                :class="getValidationStateNonBootstrap(validationContext)"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="job name"
            rules="required|min:2"
          >
            <b-form-group label="Job Name">
              <b-form-input
                v-model="newOrderSetupDetails.jobName"
                type="text"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            v-if="isAdmin"
            #default="validationContext"
            ref="user"
            name="user"
            rules="required"
          >
            <b-form-group label="User">
              <user-picker
                v-model="newOrderSetupDetails.userId"
                :class="getValidationStateNonBootstrap(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-form>
      </validation-observer>
      <template v-slot:modal-footer>
        <b-button
          @click="showNewModalOrder = false"
        >
          Go Back
        </b-button>

        <spinner-button
          ref="newOrder"
          variant="primary"
          type="submit"
          form="createOrderForm"
        >
          Create
        </spinner-button>
      </template>
    </b-modal>

    <icon-modal
      ref="contactModal"
      icon="AlertOctagonIcon"
      icon-classes="text-warning"
    >
      <p class="text-center">
        Please contact the office at {{ locationPhone }} to cancel this order.
      </p>
      <template v-slot:footer>
        <b-button
          variant="default"
          @click="$refs.contactModal.close()"
        >
          Go Back
        </b-button>
      </template>
    </icon-modal>

    <order-signature-modal
      ref="signatureModal"
      :order="selectedOrder"
    />
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BRow,
  BCol,
  BButton,
  BTable,
  BPagination,
  BSpinner,
  BBadge,
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import store from '@/store'
import {
  ref, onUnmounted, computed, watch,
} from '@vue/composition-api'
import orderStoreService from '@/services/orderStoreService'
import IconModal from '@/layouts/components/IconModal.vue'
import SpinnerButton from '@/layouts/components/SpinnerButton.vue'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@core/utils/validations/validations'
import LocationPicker from '@/layouts/components/LocationPicker.vue'
import OrderSignatureModal from '@/views/OrderSignatureModal.vue'
// eslint-disable-next-line import/no-cycle
import SessionService from '@/services/sessionService'
import UserPicker from '@/layouts/components/UserPicker.vue'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import vSelect from 'vue-select'
import OrderStatuses from '@/enums/OrderStatuses'

export default {
  filters: {
    dateFormat: date => {
      if (date != null) {
        // It is recommended to not use moment-timezone anymore so this is how to format a date with JS :)
        const options = {
          weekday: 'short',
          day: 'numeric',
          month: 'short',
          year: 'numeric',
          hour: 'numeric',
          minute: '2-digit',
        }
        const epoch = Date.parse(`${date}+00:00`)
        const local = new Date(0)
        local.setUTCMilliseconds(epoch)
        return local.toLocaleString([], options)
      }
      return ''
    },
  },
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BRow,
    BCol,
    BButton,
    BTable,
    BPagination,
    BSpinner,
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    IconModal,
    SpinnerButton,
    vSelect,
    BBadge,
    DateRangePicker,

    ValidationProvider,
    ValidationObserver,

    LocationPicker,
    UserPicker,
    OrderSignatureModal,
  },
  data: () => ({
    required,
  }),
  computed: {
    OrderStatuses() {
      return OrderStatuses
    },
  },
  setup() {
    // Store setup
    const ORDER_STORE_MODULE_NAME = 'app-order'
    if (!store.hasModule(ORDER_STORE_MODULE_NAME)) store.registerModule(ORDER_STORE_MODULE_NAME, orderStoreService)

    onUnmounted(() => {
      if (store.hasModule(ORDER_STORE_MODULE_NAME)) store.unregisterModule(ORDER_STORE_MODULE_NAME)
    })

    // Variables
    const toast = useToast()
    const isAdmin = SessionService.isAdmin()
    const buttons = ref([])

    const refOrdersTable = ref(null)
    const confirm = ref(null)
    const newOrder = ref(null)

    const showNewModalOrder = ref(false)
    const blankNewOrderDetails = {
      locationId: null,
      jobName: null,
      userId: null,
    }
    const newOrderSetupDetails = ref(JSON.parse(JSON.stringify(blankNewOrderDetails)))
    const locations = ref([])

    const orders = ref([])
    const loading = ref(false)
    const deleting = ref(false)
    const tableColumns = [
      {
        key: 'order_id',
        label: 'Order Number',
      },
      {
        key: 'order_job_name',
        label: 'Job Name',
      },
      {
        key: 'items_sum_order_item_quantity',
        label: 'Items in Order',
      },
      {
        key: 'status',
      },
      {
        key: 'order_date',
      },
      {
        key: 'actions',
      },
    ]

    if (isAdmin) {
      tableColumns.splice(1, 0, {
        key: 'customer',
      })
    }

    const perPage = ref(25)
    const totalItems = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const isAddingNew = ref(false)
    const orderId = ref(null)
    const locationPhone = ref(null)
    const contactModal = ref(null)
    const printingOrderIds = ref([])

    const future = new Date()
    future.setDate(future.getDate() + 30)

    const dateRange = ref({
      startDate: new Date(),
      endDate: future,
    })
    const userId = ref(null)
    const statuses = {
      ready: {
        value: 0,
        variant: 'success',
      },
      inProgress: {
        value: 1,
        variant: 'secondary',
      },
      scheduled: {
        value: 2,
        variant: 'primary',
      },
      open: {
        value: 3,
        variant: 'primary',
      },
      held: {
        value: 4,
        variant: 'info',
      },
      completed: {
        value: 5,
        variant: 'success',
      },
      abandoned: {
        value: 6,
        variant: 'danger',
      },
      cancelled: {
        value: 7,
        variant: 'danger',
      },
    }

    const {
      refFormObserver, getValidationState, getValidationStateNonBootstrap,
    } = formValidation()

    // Computed
    const dataMeta = computed(() => {
      const localItemsCount = refOrdersTable.value ? refOrdersTable.value.localItems.length : 0

      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalItems.value,
      }
    })

    // Functions
    const fetch = () => {
      if (isAdmin) {
        // eslint-disable-next-line no-use-before-define
        fetchWithDates()
      } else {
        // eslint-disable-next-line no-use-before-define
        fetchOrders()
      }
    }

    const fetchOrders = (startDate = null, endDate = null) => {
      loading.value = true
      store.dispatch('app-order/fetchOrders', {
        perPage: perPage.value, currentPage: currentPage.value, startDate, endDate, userId: userId.value,
      })
        .then(response => {
          orders.value = response.data.data.sort((a, b) => statuses[a.order_status]?.value - statuses[b.order_status]?.value)
          totalItems.value = response.data.total
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong please try again later',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          loading.value = false
        })
    }
    const toggleConfirmDelete = order => {
      if (order.location !== undefined) {
        // Check if we are inside the cancellation lead time range
        let cutoff = new Date()
        // Our order datetime is done in UTC, so we need to adjust our current date to also be in UTC
        cutoff = cutoff.getTime() + (cutoff.getTimezoneOffset() * 60 * 1000)
        const orderDatetime = new Date(Date.parse(order.order_datetime) - (order.location.location_order_cancellation_lead_hours * 60 * 60 * 1000))
        if (orderDatetime.getTime() < cutoff) {
          locationPhone.value = order.location.location_phone_number
          contactModal.value.open()
          return
        }
      }

      orderId.value = order.order_id
      confirm.value.open()
    }
    const deleteOrder = () => {
      deleting.value = true
      store.dispatch('app-order/cancelOrder', {
        orderId: orderId.value,
      }).then(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Successfully canceled the order',
            icon: 'CheckSquareIcon',
            variant: 'success',
          },
        })
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong when trying to delete your order please try again later',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }).finally(() => {
        deleting.value = false
        fetch()
        confirm.value.close()
      })
    }
    const completeOrder = data => {
      let button = buttons.value
      if (Array.isArray(button)) {
        [button] = button[data.index]
      }
      button.start()
      store.dispatch(`${ORDER_STORE_MODULE_NAME}/completeOrder`, { orderId: data.item.order_id })
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Successfully completed this order',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          fetch()
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong completing this order please try again later',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => { button.stop() })
    }
    const setupOrder = () => {
      showNewModalOrder.value = true
    }
    const resetSetupOrderDetails = () => {
      newOrderSetupDetails.value = JSON.parse(JSON.stringify(blankNewOrderDetails))
    }
    const createOrder = () => {
      newOrder.value.start()
      const request = {
        location_id: newOrderSetupDetails.value.locationId,
        job_name: newOrderSetupDetails.value.jobName,
      }

      if (newOrderSetupDetails.value.userId != null && newOrderSetupDetails.value.userId > 0) {
        request.user_id = newOrderSetupDetails.value.userId
      }

      store.dispatch('app-order/newOrder', request)
        .then(response => {
          router.push({
            name: 'shop',
            params: response.data,
          })
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong please try again later',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          newOrder.value.stop()
        })
    }
    const printOrderLabel = order => {
      if (order.order_status === 'ready') {
        printingOrderIds.value.push(+order.order_id)

        const removeOrderIdFromStatus = () => {
          const index = printingOrderIds.value.indexOf(+order.order_id)
          if (index > -1) {
            printingOrderIds.value.splice(index, 1)
          }
        }

        store.dispatch(`${ORDER_STORE_MODULE_NAME}/orderLabelData`, {
          orderId: order.order_id,
          paletteCount: order.order_palette_count != null ? +order.order_palette_count : 1,
        })
          .then(response => {
            // eslint-disable-next-line no-undef
            BrowserPrint.getDefaultDevice('printer', device => {
              // Add device to list of devices and to html select element
              console.log('Selected device:', device)
              console.log('Printer data:', response.data.label_data)

              device.send(response.data.label_data, () => removeOrderIdFromStatus(), error => {
                toast({
                  component: ToastificationContent,
                  props: {
                    title: error,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
                removeOrderIdFromStatus()
              })
            })
          }).catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong when printing your label please try again later',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
            removeOrderIdFromStatus()
          })
      } else {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Not all items have been fulfilled. Please finish fulfillment',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    }
    const variantForStatus = status => statuses[status]?.variant
    const statusForOrder = order => {
      if (order.order_status === 'ready') {
        return order.order_type === 'pickup' ? 'ready for pickup' : 'ready for delivery'
      }
      if (order.order_status === 'inProgress') {
        return 'In Progress'
      }

      return order.order_status
    }
    const fetchWithDates = () => {
      const start = new Date(dateRange.value.startDate.valueOf())
      start.setUTCHours(0)
      start.setUTCMinutes(0)
      start.setUTCSeconds(0)
      start.setUTCMilliseconds(0)
      const end = new Date(dateRange.value.endDate.valueOf())
      end.setUTCHours(0)
      end.setUTCMinutes(0)
      end.setUTCSeconds(0)
      end.setUTCMilliseconds(0)
      fetchOrders(start.toISOString().replace('Z', '000Z'), end.toISOString().replace('Z', '000Z'))
    }

    // Watch
    watch([
      perPage,
      currentPage,
    ], () => {
      if (isAdmin) {
        fetchWithDates()
      } else {
        fetchOrders()
      }
    })

    fetch()

    const signatureModal = ref(null)
    const selectedOrder = ref({})
    const showSignatureModal = order => {
      selectedOrder.value = order
      signatureModal.value.open()
    }

    return {
      refOrdersTable,
      confirm,
      newOrder,
      newOrderSetupDetails,
      showNewModalOrder,
      printingOrderIds,

      orders,
      loading,
      deleting,
      tableColumns,
      perPage,
      totalItems,
      currentPage,
      perPageOptions,
      dataMeta,
      isAddingNew,
      orderId,
      statuses,
      locations,
      isAdmin,
      dateRange,
      userId,
      locationPhone,
      signatureModal,
      selectedOrder,

      buttons,

      contactModal,
      refFormObserver,
      getValidationStateNonBootstrap,
      getValidationState,

      variantForStatus,
      statusForOrder,
      fetchOrders,
      toggleConfirmDelete,
      deleteOrder,
      completeOrder,
      createOrder,
      resetSetupOrderDetails,
      setupOrder,
      fetchWithDates,
      printOrderLabel,
      showSignatureModal,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
</style>
