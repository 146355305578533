export default Object.freeze({
  ORDER_STATUS_OPEN: 'open',

  ORDER_STATUS_HELD: 'held',

  ORDER_STATUS_SCHEDULED: 'scheduled',

  ORDER_STATUS_READY: 'ready',

  ORDER_STATUS_IN_PROGRESS: 'inProgress',

  ORDER_STATUS_COMPLETED: 'completed',

  ORDER_STATUS_CANCELLED: 'cancelled',
})
