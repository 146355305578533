<template>
  <v-select
    v-model="user"
    :options="users"
    :reduce="val => val.user_id"
    :loading="loading"
    :clearable="clearable"
    label="user_first_name"
    class="form-control p-0 border-control"
    placeholder="Search for a customer"
    @search="searchCustomers"
  >
    <template v-slot:option="option">
      {{ option.user_first_name }} {{ option.user_last_name }}
    </template>
    <template v-slot:selected-option="option">
      {{ option.user_first_name }} {{ option.user_last_name }}
    </template>
  </v-select>
</template>

<script>
import vSelect from 'vue-select'
import { computed, onUnmounted, ref } from '@vue/composition-api'
import userStoreService from '@/services/userStoreService'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import RoleTypes from '@/enums/RoleTypes'

export default {
  components: {
    vSelect,
  },
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    includedTypes: {
      type: Array,
      required: false,
      default: () => [RoleTypes.Customer.get()],
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    // Store
    const USER_STORE_KEY = 'app-user'

    if (!store.hasModule(USER_STORE_KEY)) store.registerModule(USER_STORE_KEY, userStoreService)

    onUnmounted(() => {
      if (store.hasModule(USER_STORE_KEY)) store.unregisterModule(USER_STORE_KEY)
    })

    // computed
    const user = computed({
      get() {
        return props.value
      },
      set(val) {
        emit('input', val)
      },
    })

    // Variables
    const loading = ref(false)
    const users = ref([])
    const toast = useToast()

    const timer = ref(null)

    // Functions
    const searchCustomers = search => {
      clearTimeout(timer.value)

      if (search != null && search.length > 0) {
        timer.value = setTimeout(() => {
          loading.value = true
          const params = {
            page: 1,
            perPage: 50,
            role: RoleTypes.Customer.get(),
            search,
          }

          store.dispatch(`${USER_STORE_KEY}/fetchUsers`, params)
            .then(response => {
              users.value = response.data.data
            }).catch(() => {
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Something went wrong fetching users please try again later',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            }).finally(() => {
              loading.value = false
            })
        }, 500)
      }
    }

    return {
      user,
      loading,
      users,

      searchCustomers,
    }
  },
}
</script>

<style lang="scss" scoped>
.border-control::v-deep .vs__dropdown-toggle {
  border: 0;
  border-radius: 0;
}
</style>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
</style>
