import axios from '@axios'
import RoleTypes from '@/enums/RoleTypes'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // eslint-disable-next-line no-unused-vars
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/users', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(ctx, userData) {
      const headers = {
        headers: {
          'Content-Type': 'multipart/form-data;',
        },
      }
      const formData = new FormData()
      formData.append('first_name', userData.user_first_name)
      formData.append('last_name', userData.user_last_name)
      formData.append('email', userData.user_email)
      formData.append('password', userData.password)
      formData.append('password_confirmation', userData.password_confirmation)
      formData.append('role', userData.user_role)
      if (userData.user_role === RoleTypes.Worker.get()) {
        formData.append('location_id', userData.user_location_id)
      }

      if (userData.avatar_image != null) {
        formData.append('avatar_image', userData.avatar_image)
      }

      return new Promise((resolve, reject) => {
        axios
          .post('/users', formData, headers)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateUser(ctx, { data, discounts, fees }) {
      const headers = {
        headers: {
          'Content-Type': 'multipart/form-data;',
        },
      }
      const formData = new FormData()
      formData.append('first_name', data.user_first_name)
      formData.append('last_name', data.user_last_name)
      formData.append('can_receive_deliveries', JSON.stringify(+data.user_can_receive_deliveries === 1))
      formData.append('is_taxable', JSON.stringify(+data.user_is_taxable === 1))
      formData.append('discounts', JSON.stringify(discounts))
      formData.append('fees', JSON.stringify(fees))
      if (data.user_role === RoleTypes.Worker.get()) {
        formData.append('location_id', data.user_location_id)
      }

      if (data.avatar_image != null) {
        formData.append('avatar_image', data.avatar_image)
      }
      return new Promise((resolve, reject) => {
        axios.post(`users/${data.user_id}`, formData, headers)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios.delete(`users/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCustomers() {
      return new Promise((resolve, reject) => {
        axios
          .get('/users/import-options')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    importCustomer(ctx, {
      customerData, canReceiveDeliveries, isTaxable, discounts,
    }) {
      return new Promise((resolve, reject) => {
        axios.post('users/import', {
          importData: customerData.data,
          canReceiveDeliveries,
          isTaxable,
          discounts,
        }).then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    discountsAndFeesForUser(ctx, { userId }) {
      return new Promise((resolve, reject) => {
        axios.get(`/users/${userId}/discounts-and-fees`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    reactivateUser(ctx, { userId }) {
      return new Promise((resolve, reject) => {
        axios.post(`/users/${userId}/reactivate`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
