import axios from '@axios'

export default {
  namespaced: true,
  state: {
    orderSummary: {
      order_location: {
        location_id: null,
        location_name: null,
        location_builder_disclaimer_title: '',
        location_builder_disclaimer_message: '',
      },
      order_items: [],
      order_from_builder: 0,
      order_customer: {
        user_first_name: '',
        user_last_name: '',
      },
      order_pallete_count: 0,
      order_job_name: '',
      order_status: '',
    },
  },
  getters: {
    orderSummary: state => state.orderSummary,
    fromBuilder: state => +state.orderSummary.order_from_builder === 1,
    builderDisclaimerTitle: state => state.orderSummary.order_location.location_builder_disclaimer_title,
    builderDisclaimerMessage: state => state.orderSummary.order_location.location_builder_disclaimer_message,
  },
  mutations: {
    updateOrderSummary(state, payload) {
      state.orderSummary = payload
    },
  },
  actions: {
    fetchOrders(ctx, {
      perPage, currentPage, startDate = null, endDate = null, userId = null,
    }) {
      return new Promise((resolve, reject) => {
        axios.get(`/orders?perPage=${perPage}&page=${currentPage}&startDatetime=${startDate}&endDatetime=${endDate}&userId=${userId}`).then(response => resolve(response)).catch(error => reject(error))
      })
    },
    fetchOrder(ctx, { orderId }) {
      return new Promise((resolve, reject) => {
        axios.get(`/orders/${orderId}/items`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    checkOrderItems(ctx, { orderId }) {
      return new Promise((resolve, reject) => {
        axios.get(`orders/${orderId}/check-items`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    newOrder(ctx, request) {
      return new Promise((resolve, reject) => {
        axios.post('/orders', request)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addItemToOrder(ctx, { orderId, id, quantity }) {
      return new Promise((resolve, reject) => {
        axios.post(`/orders/${orderId}/items`, {
          item_id: id,
          quantity,
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateItemInOrder(ctx, { orderId, id, quantity }) {
      return new Promise((resolve, reject) => {
        axios.put(`/orders/${orderId}/items`, {
          item_id: id,
          quantity,
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeItemFromOrder(ctx, { orderId, id }) {
      return new Promise((resolve, reject) => {
        axios.delete(`/orders/${orderId}/items/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addItemGroupToOrder(ctx, { orderId, id, quantity }) {
      return new Promise((resolve, reject) => {
        axios.post(`/orders/${orderId}/item-groups`, {
          item_group_id: id,
          quantity,
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateItemGroupInOrder(ctx, { orderId, id, quantity }) {
      return new Promise((resolve, reject) => {
        axios.put(`/orders/${orderId}/item-groups`, {
          item_group_id: id,
          quantity,
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeItemGroupFromOrder(ctx, { orderId, id }) {
      return new Promise((resolve, reject) => {
        axios.delete(`/orders/${orderId}/item-groups/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchOrderSummary(ctx, { orderId }) {
      return new Promise((resolve, reject) => {
        axios.get(`/orders/${orderId}/summary`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    saveOrderForLater(ctx, { orderId }) {
      return new Promise((resolve, reject) => {
        axios.post(`/orders/${orderId}/save`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    scheduleOrder(ctx, { orderId, submissionDetails }) {
      return new Promise((resolve, reject) => {
        axios.post(`/orders/${orderId}/submit`, submissionDetails)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    submitBuilder(ctx, { orderId, submissionDetails }) {
      return new Promise((resolve, reject) => {
        axios.post(`/orders/${orderId}/build`, submissionDetails)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    cancelOrder(ctx, { orderId }) {
      return new Promise((resolve, reject) => {
        axios.delete(`/orders/${orderId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateOrderLocation(ctx, { orderId, locationId }) {
      return new Promise((resolve, reject) => {
        axios.put(`/orders/${orderId}/location`, {
          location_id: locationId,
        }).then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    completeOrder(ctx, { orderId }) {
      return new Promise((resolve, reject) => {
        axios.put(`/orders/${orderId}/complete`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    orderLabelData(ctx, { orderId, paletteCount }) {
      return new Promise((resolve, reject) => {
        axios.get(`/orders/${orderId}/label-data?paletteCount=${paletteCount}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    saveOrderSignature(ctx, { orderId, signature }) {
      return new Promise((resolve, reject) => {
        fetch(signature)
          .then(res => res.blob())
          .then(blob => {
            const file = new File([blob], 'signature.png', {
              type: 'image/png',
            })

            const headers = {
              'Content-Type': 'multipart/form-data',
            }

            const formData = new FormData()
            formData.append('signature', file)

            axios.post(`orders/${orderId}/sign`, formData, headers)
              .then(response => resolve(response))
              .catch(error => reject(error))
          })
      })
    },
  },
}
