<template>
  <b-modal
    v-model="show"
    hide-header
    ok-only
  >
    <div v-if="orderSummary != null">
      <h4>Items</h4>
      <b-table
        :items="orderSummary.order_items"
        :fields="fields"
        responsive
        show-empty
      />
    </div>

    <div v-if="order.order_signature_url != null || order.order_completed_at != null">
      <h4>Signature</h4>
      <b-img-lazy
        v-if="order.order_signature_url != null"
        :src="order.order_signature_url"
        class="w-100"
        alt="order signature"
      />
      <p
        v-if="order.order_completed_at != null"
        class="text-center"
      >
        Order signed for on {{ orderCompletedAt }}
      </p>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal,
  BImgLazy,
  BTable,
} from 'bootstrap-vue'
import axios from '@axios'

export default {
  components: {
    BModal,
    BImgLazy,
    BTable,
  },
  props: {
    order: {
      type: Object,
      required: true,
      default: () => ({
        order_signature_url: '',
        order_completed_at: '',
      }),
    },
  },
  data: () => ({
    show: false,
    orderSummary: null,
  }),
  computed: {
    orderCompletedAt() {
      const options = {
        weekday: 'short',
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
      }
      const epoch = Date.parse(`${this.order.order_completed_at}+00:00`)
      const local = new Date(0)
      local.setUTCMilliseconds(epoch)
      return local.toLocaleString([], options)
    },
    fields() {
      return [
        {
          key: 'order_item_name',
          label: 'Item Name',
        },
        {
          key: 'order_item_quantity',
          label: 'Quantity',
        },
      ]
    },
  },
  watch: {
    order(newValue) {
      this.orderSummary = null
      axios.get(`/orders/${newValue.order_id}/summary`)
        .then(res => {
          this.orderSummary = res.data
        })
    },
  },
  methods: {
    open() {
      this.show = true
    },
    close() {
      this.show = false
    },
  },
}
</script>
