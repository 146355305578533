<template>
  <b-container class="min-height d-flex flex-column justify-content-between p-1">
    <div>
      <div
        class="mb-0 bg-primary text-center rounded-corners py-1"
      >
        <h1 class="text-light">
          {{ orderSummary.order_customer.user_first_name }} {{ orderSummary.order_customer.user_last_name }}
        </h1>
        <h2 class="text-light">
          {{ orderSummary.order_job_name }} - #{{ orderId }}
        </h2>
        <h3 class="text-light mb-0">
          {{ orderSummary.order_pallete_count }} Pallet{{ +orderSummary.order_pallete_count === 1 ? '' : 's' }}
        </h3>
      </div>

      <b-table
        :items="orderSummary.order_items"
        :busy="loading"
        :fields="fields"
        responsive
        show-empty
      />
    </div>

    <b-alert
      v-if="showSuccess"
      show
      class="mt-2 d-flex justify-content-center align-items-center"
      style="height: 50vh"
      variant="success"
    >
      <h1 class="text-center mx-1">
        Order signature saved.
      </h1>
    </b-alert>
    <template v-else-if="showSigPad">
      <vue-signature
        ref="confirmationSignatureRef"
        h="50vh"
        class="my-2"
        :sig-option="signatureConfig"
      />

      <b-row>
        <b-col cols="6">
          <b-button
            variant="secondary"
            block
            size="lg"
            @click="$refs.confirmationSignatureRef.clear()"
          >
            Clear
          </b-button>
        </b-col>
        <b-col cols="6">
          <spinner-button
            variant="primary"
            :loading="saving"
            block
            size="lg"
            @click="saveSignature"
          >
            Done
          </spinner-button>
        </b-col>
      </b-row>
    </template>
    <b-alert
      v-else
      show
      class="mt-2 d-flex justify-content-center align-items-center"
      style="height: 50vh"
      variant="danger"
    >
      <h1 class="text-center mx-1">
        Order is not yet ready for signature.
      </h1>
    </b-alert>
  </b-container>
</template>

<style scoped>
.rounded-corners {
  border-top-right-radius: 0.6rem;
  border-top-left-radius: 0.6rem;
}

canvas {
  border-radius: 0.6rem !important;
}

.min-height {
  min-height: 100vh;
}
</style>

<script>
import vueSignature from 'vue-signature'
import SpinnerButton from '@/layouts/components/SpinnerButton.vue'
import { onUnmounted, ref, computed } from '@vue/composition-api'
import store from '@/store'
import OrderStoreService from '@/services/orderStoreService'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BContainer, BButton, BRow, BCol, BAlert, BTable,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import OrderStatuses from '@/enums/OrderStatuses'
import orders from './Orders.vue'

export default {
  components: {
    vueSignature,
    SpinnerButton,
    BContainer,
    BButton,
    BRow,
    BCol,
    BAlert,
    BTable,
  },
  computed: {
    orders() {
      return orders
    },
  },
  setup() {
    // Store
    const ORDER_STORE_KEY = 'app-order'
    if (!store.hasModule(ORDER_STORE_KEY)) {
      store.registerModule(ORDER_STORE_KEY, OrderStoreService)
    }

    onUnmounted(() => {
      if (store.hasModule(ORDER_STORE_KEY)) {
        store.unregisterModule(ORDER_STORE_KEY)
      }
    })

    const toast = useToast()

    const orderId = router.currentRoute.params.order_id
    const signatureConfig = {
      penColor: 'rgb(40, 88, 162)',
      backgroundColor: '#EEEEEE',
    }

    const saving = ref(false)
    const loading = ref(true)
    const showSigPad = ref(true)
    const confirmationSignatureRef = ref(null)

    const checkOrderStatus = order => {
      if (order.order_status !== OrderStatuses.ORDER_STATUS_READY) {
        showSigPad.value = false
      }

      return showSigPad.value
    }

    const fetchOrderDetails = () => {
      store.dispatch(`${ORDER_STORE_KEY}/fetchOrderSummary`, { orderId })
        .then(response => {
          checkOrderStatus(response.data)
          store.commit(`${ORDER_STORE_KEY}/updateOrderSummary`, response.data)
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong fetching your order please try again later',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          loading.value = false
        })
    }

    fetchOrderDetails()

    const orderSummary = computed(() => store.getters[`${ORDER_STORE_KEY}/orderSummary`])
    const showSuccess = computed(() => orderSummary.value.order_status === OrderStatuses.ORDER_STATUS_COMPLETED)

    const saveSignature = () => {
      if (!confirmationSignatureRef.value.isEmpty()) {
        saving.value = true
        const signature = confirmationSignatureRef.value.save()
        store.dispatch(`${ORDER_STORE_KEY}/saveOrderSignature`, {
          orderId,
          signature,
        })
          .then(() => {
            fetchOrderDetails()
            toast({
              component: ToastificationContent,
              props: {
                title: 'Successfully saved the orders signature',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong trying to save your signature please try again later',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
          .finally(() => {
            saving.value = false
          })
      } else {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Please add a signature',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },

        })
      }
    }

    const fields = [
      {
        key: 'order_item_name',
        label: 'Item Name',
      },
      {
        key: 'order_item_quantity',
        label: 'Quantity',
      },
    ]

    return {
      signatureConfig,
      saving,
      loading,
      showSuccess,

      fields,

      saveSignature,
      orderSummary,
      orderId,
      showSigPad,
      confirmationSignatureRef,
    }
  },
}
</script>
